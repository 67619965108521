<template>
  <div class="home-container">
    <div class="has-text-centered title-section is-marginless">
      <h5 class="title has-text-danger">{{langDisplayName}}</h5>
    </div>
    <div class="box has-background-white-ter no-border-print shared-error" v-if="shareNotFound">
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    </div>
    <div class="box has-background-white-ter main-box no-border-print" v-if="!shareNotFound">
      <div class="columns is-mobile">
        <div class="column is-12 has-text-weight-semibold ide-title has-text-centered" v-if="project && project.name">Project Name: <span class="has-text-danger">{{project.name}}</span></div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6 colhtml-inp is-print-12">
          <div>
            <div id="html-doctype-code" class="code-editor no-border-print html-doctype-editor">&lt;!DOCTYPE html&gt;</div>
          </div>
          <div>
            <span class="keyword"> &lt;html&gt; </span>
          </div>
          <div>
            <span class="keyword"> &nbsp;&nbsp; &lt;head&gt; </span>
            <!--<button id="add-js-libs" class="button is-small is-rounded is-danger has-text-weight-bold is-hidden-print" type="button" @click="showJSLibs">
              <font-awesome-icon icon="plus-square"/>&nbsp;&nbsp;Include Lib
              </button>-->
          </div>
          <div>
            <div id="html-head-code" class="code-editor no-border-print html-head-editor">&lt;script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js"&gt;&lt;/script&gt;</div>
          </div>
          <div>
            <span class="keyword"> &nbsp;&nbsp; &lt;/head&gt; </span>
          </div>
          <div>
            <span class="keyword"> &nbsp;&nbsp; &lt;body&gt; </span>
          </div>
          <div>
            <div id="html-body-code" class="code-editor no-border-print html-body-editor">&lt;div class="welcome"&gt;Welcome To JDoodle.com&lt;/div&gt;</div>
          </div>
          <div>
            <span class="keyword"> &nbsp;&nbsp; &lt;body&gt; </span>
          </div>
          <div class="keyword">&lt;/html&gt;</div>

        </div>
        <div class="column is-6 is-print-12">
          <div class="js-inp">
            <div>
              <span class="keyword">&lt;script type="text/javascript"&gt; </span>
            </div>
            <div>
              <div id="js-code" class="code-editor js-editor no-border-print">$(document).ready(function() {
  $(".welcome").append("<span>!!!</span>");
});</div>
            </div>
            <div class="keyword">&lt;/script&gt;</div>
          </div>
          <div class="css-inp">
            <div>
              <span class="keyword">&lt;style type="text/css"&gt; </span>
            </div>
            <div>
              <div id="css-code" class="code-editor css-editor no-border-print">.welcome {
  color:green;
  font-size:36px;
  font-family:cursive;
  text-align:center;
  padding:20px;
}</div>
            </div>
            <div class="keyword">&lt;/style&gt;</div>
          </div>
        </div>
      </div>
      <div class="level execute-section is-hidden-print">
        <div class="level-item">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-info has-text-dark has-text-weight-bold" @click="ideExecute">
                <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
                <span v-if="isCodeExecuting">
                  <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
              </button>
            </p>
            <p class="control">
                <button class="button has-text-dark has-text-weight-bold more-button"
                        @click="showIdeMenu">
                  <font-awesome-icon icon="ellipsis-h"/>
                </button>
            </p>
          </div>
        </div>
      </div>
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
      <div class="has-text-weight-semibold ide-title">Result</div>
      <div>
        <div class="output">
            <iframe name="output" frameborder="0" sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"></iframe>
        </div>
      </div>
    </div>
    <div id="download-div" style="display:none">
      <form name="html-download-form" method="post" action="/api/doodle/download/web.html">
        <textarea name="code"></textarea>
      </form>
    </div>
    <CommentBox class="is-hidden-print"/>
  </div>
</template>

<script>
import CommentBox from '../components/Utils/CommentsBox'
import aceMixin from './../assets/javascript/html-ide-mixin'
import { eventBus } from './../assets/javascript/event-bus'
import { CMD_LANGUAGES, GURU_EVENTS, IDE_CONST } from '../assets/javascript/constants'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import { ACE_UTIL } from '@/assets/javascript/ace-util'

let ideMenu = () => import('../components/IdeMenu')

export default {
  name: 'htmlIde',
  components: { DoodleMessage, CommentBox },
  mixins: [aceMixin, formMixin],
  data: function () {
    return {
      project: null,
      autoSaveOn: false,
      ideMeta: {},
      shareNotFound: false
    }
  },
  mounted () {
    this.ideMeta = this.$route.meta
    ACE_UTIL.loadACELibraries()

    if (!this.ideMeta.isShared) {
      this.$_.delay(this.initEditors, 500, 1)
    }

    if (this.ideMeta.isShared) {
      this.executeAPIWitoutValiation({
        url: '/api/doodle/shareIndex',
        data: { shareId: this.$route.params.shareId, lang: 'html' },
        method: 'post',
        successAction: (data) => {
          this.$_.delay(this.initEditors, 500, 1)
          let postAction = (project, count) => {
            if (window.ace) {
              this.project = project
              this.setCodeInEditor()
            } else if (count < 10) {
              this.$_.delay(postAction, 600, project, count + 1)
            }
          }
          this.$_.delay(postAction, 600, data.project, 1)
        },
        failureAction: () => {
          this.shareNotFound = true
        },
        markCompleted: true,
        form: this.doodleForm
      })
    }

    loadScriptInBody.loadScriptInBody('https://togetherjs.com/togetherjs-min.js')

    window.onbeforeunload = function () {
      return 'Are you sure you want to move from this IDE?'
    }

    eventBus.$on('HtmlIdeUpdateLibraries', (libs) => {
      this.javaLibraries = libs
    })

    eventBus.$on('HtmlIdeClearProject', () => {
      this.project = null
      this.reset()
    })

    eventBus.$on('HtmlIdeOpenProject', (data) => {
      this.reset()
      this.project = data.project
      this.setCodeInEditor()
    })

    eventBus.$on('HtmlIdeProjectSaved', (project) => {
      this.project = project
    })

    eventBus.$on('HtmlIdeStorageChanged', () => {
      this.checkStorage()
    })

    eventBus.$on('HtmlIdeAutoSave', (autoSaveOn) => {
      this.autoSaveOn = autoSaveOn
    })

    this.checkStorage()
  },
  beforeDestroy () {
    eventBus.$off('HtmlIdeUpdateLibraries')
    eventBus.$off('HtmlIdeClearProject')
    eventBus.$off('HtmlIdeOpenProject')
    eventBus.$off('HtmlIdeProjectSaved')
    eventBus.$off('HtmlIdeStorageChanged')
    eventBus.$off('HtmlIdeAutoSave')
  },
  beforeRouteLeave (to, from, next) {
    if (window.confirm('Do you want to leave ' + this.ideMeta.langDisplayName + ' IDE?')) {
      next()
    } else {
      next(false)
    }
  },
  computed: {
    showCommandLine () {
      return !CMD_LANGUAGES.includes(this.ideMeta.language)
    },
    isShared () {
      return this.$route.meta.isShared
    },
    interactiveMode () {
      return this.interactiveModeOption === true
    },
    langDisplayName () {
      return this.ideMeta.langDisplayName
    },
    language () {
      return this.ideMeta.language
    },
    versions () {
      return this.ideMeta.versions
    },
    downloadFileName () {
      return 'jdooodle' + this.ideMeta.filename
    },
    aceLanguageCode () {
      return this.ideMeta.aceCode ? this.ideMeta.aceCode : this.ideMeta.language
    },
    isCompileLang () {
      return this.ideMeta.isCompile || this.ideMeta.language === 'java'
    }
  },
  methods: {
    showJSLibs () {
      // https://cdn.jsdelivr.net/npm/
      // https://api-docs.npms.io/
    },
    setCodeInEditor () {
      window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().setValue(this.project.docType)
      window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().setValue(this.project.htmlHead)
      window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().setValue(this.project.htmlBody)
      window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().setValue(this.project.cssCode)
      window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().setValue(this.project.jsCode)
    },
    checkStorage  () {
      if (localStorage.getItem('JDoodleRecent')) {
        this.isLocalStoreEnabled = true
        this.storage = localStorage
      } else {
        this.storage = sessionStorage
      }
    },
    showIdeMenu () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, ideMenu, {
        language: this.language,
        languageName: this.langDisplayName,
        project: this.project,
        storage: this.storage,
        autoSaveOn: this.autoSaveOn,
        downloadFileName: this.downloadFileName,
        aceLanguageCode: this.aceLanguageCode
      })
    },
    ideExecute () {
      this.execute()

      if (this.autoSaveOn && this.project && this.project.id) {
        let data = {
          htmlBody: window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue(),
          htmlHead: window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue(),
          jsCode: window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue(),
          cssCode: window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue(),
          docType: window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().getValue(),
          filename: this.project.name,
          id: this.project.id,
          lang: this.language
        }

        this.executeAPIWitoutValiation({
          url: '/api/doodle/save',
          data: data,
          method: 'post',
          markCompleted: true,
          form: this.doodleForm,
          jdaCategory: window.jda.CATEGORY.IDE,
          jdaEvent: 'save-auto',
          jdaLabel: this.language
        })
      }
    },
    postExecuteSuccessHandling () {

    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
@import "./../assets/style/htmlide";

  .main-box {
    padding: 1em 0.45em;
  }

  .home-container {
    margin-top: 0.1em;
  }

  .ide-title, .switch[type=checkbox]+label.ide-title {
    font-size: 0.9em;
  }

  .column {
    padding-top: 0.10em;
    padding-left: 0.50em;;
  }

  @media only screen and (max-width: 769px) {
    .columns, .column {
      margin: 0;
      padding: 0;
    }
  }

  @media only screen and (min-width: 769px) {
    .left-column {
      margin-top: 0.7em;
    }
  }

  .more-button {
    background: #fa745e;
  }

  .execute-section {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .padding-top-none {
    padding-top: 0 !important;
  }

  .padding-left-none {
    padding-left: 0 !important;
  }

  .shared-error {
    margin-top: 3em;
  }

  @media print {
    .code-editor {
      min-height: 0px !important;
    }
  }
</style>
