import { IDE_CONST } from './constants'
import { ACE_UTIL } from './ace-util'

export default {
  data: function () {
    return {
      isEmbed: false,
      editorHtmlDoctype: null,
      fileEditor: null,
      editorHtmlHead: null,
      editorHtmlBody: null,
      editorJs: null,
      editorCss: null,
      editors: [],
      isCodeExecuting: false,
      storage: null
    }
  },
  methods: {
    initEditors (count) {
      if (count > 10) {
        return
      }

      if (!window.ace) {
        this.$_.delay(this.initEditors, 500, count + 1)
        return
      }

      this.editorHtmlDoctype = ACE_UTIL.initEditor(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME, 'html')
      this.editorHtmlDoctype.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME, this.editorHtmlDoctype)
      this.editorHtmlDoctype.$highlightActiveLine = false
      this.editorHtmlDoctype.getSession().setWrapLimit(80)
      this.editorHtmlDoctype.getSession().setUseWrapMode(true)

      this.editorHtmlBody = ACE_UTIL.initEditor(IDE_CONST.HTML_BODY_EDITOR_NAME, 'html')
      this.editorHtmlBody.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_BODY_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_BODY_EDITOR_NAME, this.editorHtmlBody)
      this.editorHtmlBody.$highlightActiveLine = false
      this.editorHtmlBody.getSession().setWrapLimit(80)
      this.editorHtmlBody.getSession().setUseWrapMode(true)

      this.editorHtmlHead = ACE_UTIL.initEditor(IDE_CONST.HTML_HEAD_EDITOR_NAME, 'html')
      this.editorHtmlHead.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_HEAD_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_HEAD_EDITOR_NAME, this.editorHtmlHead)
      this.editorHtmlHead.$highlightActiveLine = false
      this.editorHtmlHead.getSession().setWrapLimit(80)
      this.editorHtmlHead.getSession().setUseWrapMode(true)

      this.editorJs = ACE_UTIL.initEditor(IDE_CONST.JS_CODE_EDITOR_NAME, 'javascript')
      this.editorJs.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.JS_CODE_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.JS_CODE_EDITOR_NAME, this.editorJs)
      this.editorJs.$highlightActiveLine = false
      this.editorJs.getSession().setWrapLimit(80)
      this.editorJs.getSession().setUseWrapMode(true)

      this.editorCss = ACE_UTIL.initEditor(IDE_CONST.CSS_CODE_EDITOR_NAME, 'css')
      this.editorCss.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.CSS_CODE_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.CSS_CODE_EDITOR_NAME, this.editorCss)
      this.editorCss.$highlightActiveLine = false
      this.editorCss.getSession().setWrapLimit(80)
      this.editorCss.getSession().setUseWrapMode(true)
    },
    execute () {
      let result = window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().getValue() +
        '<html> <head>' +
        '<meta http-equiv="content-type" content="text/html; charset=UTF-8">' +
        '<meta name="robots" content="noindex, nofollow">' +
        '<meta name="googlebot" content="noindex, nofollow">' +
        window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue() +
        '<script type="text/javascript" async="true">' + window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue() + '</script>' +
        '<style type="text/css">' + window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue() + '</style>' +
        '</head><body>' + window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue() + '</body></html>'

      $('form[name=html-download-form] textarea[name=code]').val(result)
      $('form[name=html-download-form]').attr('target', 'output')
      $('form[name=html-download-form]').submit()

      let lastExecution = {
        docType: window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().getValue(),
        htmlHead: window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue(),
        htmlBody: window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue(),
        jsCode: window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue(),
        cssCode: window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue(),
        executedAt: $.now()
      }

      this.addToRecent(lastExecution)
    },
    postExecuteSuccessHandling () {

    },
    reset () {
      if (window.ace) {
        window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().setValue('')
      }

      $('iframe[name=output]').attr('src', 'about:blank')
    },
    addToRecent: function (lastExecution) {
      if (this.isEmbed) {
        return
      }
      var recent = JSON.parse(this.storage.getItem('JDoodleRecent'))
      if (!recent) {
        recent = {}
      }

      if (!recent[this.language]) {
        recent[this.language] = []
      }

      recent[this.language].unshift(lastExecution)
      if (recent[this.language].length > 50) {
        recent[this.language] = window._.slice(recent[this.language], 0, 50)
      }

      this.storage.setItem('JDoodleRecent', JSON.stringify(recent))
    }
  }
}
